import React, { useEffect, useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { TransitionState } from 'gatsby-plugin-transition-link';
import gsap from 'gsap';

import { get } from '../services/api';
import useLocalStorage from '../hooks/useLocalstorage';
import Layout from '../components/layout';
import Meta from '../components/meta';
import { Canvas } from '../components/canvas/canvas';
import { Sidebar } from '../components/sidebar';
import { Compas, Header, MiniMap, NavigationHelp } from '../components';
import VideoModal from '../components/videoModal';

const themes = [
	'themeAnimals',
	'themeFamily',
	'themeFood',
	'themeSociety',
	'themeTech',
	'themeTransport',
];

const MapPage = ({ location, data }) => {
	const [theme] = useLocalStorage('FKN_Theme', null);
	const [currentTheme, setCurrentTheme] = useState(theme);
	const [isModalOpen, setModalOpen] = useState(false);
	const [isVideoModalOpen, setVideoModalOpen] = useState(false);
	const [compasAngle, setCompasAngle] = useState(90);
	const [cameraPosition, setCameraPosition] = useState([0,0]);
	const [mapLoaded, setMapLoaded] = useState(false);

	const transitionRef = useRef(null);
	const { storyblokEntry } = data;
	const content = JSON.parse(storyblokEntry.content);

	useEffect(() => {
		var search = Object.fromEntries(new URLSearchParams(location.search));
		if(search.map) {
			get(`klasse/${search.map}`)
				.then(res => {
					setCurrentTheme(res.tema);
				})
				.catch(err => {
					console.warn(err);
				});
		}

		const keys = Object.keys(search);
		if(keys.length > 0 && keys.some(key => key.toLocaleLowerCase() === 'wafande')) {
			setVideoModalOpen(true);
		}

	}, []);

	const closeModal = () => setModalOpen(false);

	useEffect(() => {
		if(currentTheme === null) {
			closeModal();
		}
	}, [currentTheme]);

	const handleThemeChange = theme => {
		setCurrentTheme(theme);
		if(theme !== null) {
			setModalOpen(true);
		}
	};

	const openVideoModal = () => {
		setVideoModalOpen(true);
	};

	useEffect(() => {
		if(transitionRef !== null) {
			gsap.to(transitionRef.current, {
				opacity: 0,
				duration: .7,
				delay: .3,
			});
		}
	}, [transitionRef]);

	return (<TransitionState >
		{() => {
			return (
				<Layout className="bg-secondary">
					<Meta
						lang="en"
						title="Velkommen til Kenya"
						description="Velkommen til det intseraktive Kenya-kort"
					/>
					<Header color="#ffffff" hasBack={false} isMapPage={true}/>
					<NavigationHelp isVisible={mapLoaded} />
					<Sidebar currentTheme={currentTheme} themes={themes} handleThemeChange={handleThemeChange} />
					<Canvas
						resolution={location?.state?.resolution || 'Medium'}
						currentTheme={currentTheme}
						themes={themes}
						setCompasAngle={setCompasAngle}
						openPopUp={openVideoModal}
						setCameraPosition={setCameraPosition}
						setMapLoaded={() => setMapLoaded(true)}
					/>
					{mapLoaded && <Compas angle={compasAngle}/>}
					{mapLoaded && <MiniMap cameraPosition={cameraPosition} currentTheme={currentTheme} />}
					{isModalOpen && <VideoModal close={closeModal} currentTheme={currentTheme} video={content[`${currentTheme}Video`]} />}
					{isVideoModalOpen && <VideoModal close={() => setVideoModalOpen(false)} currentTheme={'themeMusic'} video='https://a.storyblok.com/f/126918/x/142458c5dd/wafandemusikvideo.mp4' />}
				</Layout>
			);
		}}

	</TransitionState>
	);
};

export default MapPage;

export const query = graphql`
	query mapEQuery {
		storyblokEntry(field_component: {eq: "map"}) {
			id
			content
		}
	}
`;
